<template>
  <BaseBonusPanel
    type="deposit"
    :progress="rollingProgress"
    :paused="isRollingStatusNewOrPaused"
  >
    <template #title> {{ titleByLevel }} </template>
    <template #wager>
      <span v-if="isRolling && isRollingStatusInProgress" class="wager">
        {{ t('bonuses.wager', { multiplier: rollingMultiplier }) }}
      </span>
    </template>
    <template #minmax>
      <div v-if="isRolling" class="rolling-min-max">
        <template v-if="isRollingStatusInProgress">
          <span class="rolling-min-max-value" :title="minMaxRollingText">
            {{ minMaxRollingText }}
          </span>
          <StIcon
            v-if="bonusCurrencyIcon"
            :name="bonusCurrencyIcon"
            :size="14"
          />
        </template>
        <span
          class="bonus-amount"
          :class="{ paused: isRollingStatusNewOrPaused }"
        >
          <img :src="giftImage" alt="gift" width="20" />
          <span>
            {{ bonusAmountForClaim }}
          </span>
          <StIcon
            v-if="bonusCurrencyIcon"
            :name="bonusCurrencyIcon"
            :size="14"
          />
        </span>
      </div>
    </template>
    <template #subtitle>
      <p v-if="isCanClaimed" class="bonus-text claim">
        {{ t('bonuses.depositPanelClaimText') }}
        <span>
          {{ bonusAmountForClaim }}
        </span>
        <StIcon v-if="bonusCurrencyIcon" :name="bonusCurrencyIcon" :size="14" />
      </p>
    </template>
    <template #actions>
      <StButton
        v-if="isRollingStatusNewOrPaused"
        size="m"
        type="secondary"
        :label="t('bonuses.toRoll')"
        @click="setCurrentRolling()"
      />
      <div v-else-if="isCanClaimed" class="claim-box">
        <StButton
          :label="t('bonuses.depositPanelClaim')"
          size="m"
          :loading="claimStatus === 'pending'"
          :disabled="claimStatus === 'error'"
          @click="handleBonusClaim"
        >
          {{ t('bonuses.depositPanelClaim') }}
          <ClientOnly>
            <StLottie
              v-if="claimStatus === 'success'"
              class="animation"
              :animation-data="claimAnimation"
            />
          </ClientOnly>
        </StButton>
      </div>
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import BaseBonusPanel from './BaseBonusPanel.vue'
import { useCashBonus } from '../../../composables/useCashBonus'
import { useBonusRolling } from '../../../composables/useBonusRolling'
import type { CashBonus } from '../../../types'
import giftImage from '../assets/gift.png'
import claimAnimation from '../../../assets/claim-animation.json'

const props = defineProps<{
  bonus: CashBonus
}>()

const { t } = useI18n()

const { bonus } = toRefs(props)

const {
  isRollingStatusNewOrPaused,
  isRollingStatusInProgress,
  currentRollingAmount,
  fullRollingAmount,
  setCurrentRolling,
  rollingProgress,
} = useBonusRolling(computed(() => bonus.value.rolling))

const {
  isRolling,
  isCanClaimed,
  claimStatus,
  bonusCurrencyIcon,
  bonusAmountForClaim,
  rollingMultiplier,
  titleByLevel,
  handleBonusClaim,
} = useCashBonus(bonus)

const minMaxRollingText = computed(
  () => `${currentRollingAmount.value} / ${fullRollingAmount.value}`,
)
</script>

<style scoped>
.bonus-text {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  margin: 0;

  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  &.claim {
    span {
      margin-left: var(--spacing-025);
      color: var(--text-success);
    }
  }
}

.rolling-min-max {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  font: var(--mobile-caption-1-regular);
}

.rolling-min-max-value {
  overflow: hidden;
  max-width: 145px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wager {
  font: var(--mobile-caption-1-regular);
}

.bonus-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin-left: auto;

  font: var(--mobile-caption-1-medium);
  color: var(--text-success);

  &.paused {
    margin-left: 0;
  }
}

.animation {
  position: absolute;
  overflow: visible;
}

.sub-bonus {
  overflow: hidden;
  display: flex;
  align-items: center;

  .icon {
    min-width: 14px;
    margin: 0 var(--spacing-025);
  }
}
</style>
