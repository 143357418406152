<template>
  <BaseBonusPanel type="deposit">
    <template #title> {{ titleByLevel }} </template>
    <template #subtitle>
      <div class="bonus-text new sub-bonuses">
        <div
          v-for="(subBonus, index) in filteredMultiBonuses"
          :key="index"
          class="sub-bonus"
        >
          <span v-if="isStepBonus">{{ t('bonuses.toAmount') }}</span>
          <span>
            {{ subBonus?.amount }}
          </span>
          <StIcon
            v-if="subBonus?.icon"
            :name="subBonus.icon"
            :size="16"
            class="icon"
          />
          <span v-if="subBonus?.text">
            {{ subBonus?.text }}
          </span>
          <span v-if="index + 1 < filteredMultiBonuses.length">,</span>
        </div>
      </div>
    </template>
    <template #actions>
      <StButton
        :label="t('bonuses.depositPanelMakeDeposit')"
        size="m"
        replace
        :to="{ query: { modal: 'payments', tab: 'deposit' } }"
      />
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import BaseBonusPanel from './BaseBonusPanel.vue'
import { useDepositBonus } from '../../../composables/useDepositBonus'
import type { DepositBonus } from '../../../types'

const props = defineProps<{
  bonus: DepositBonus
}>()

const { t } = useI18n()

const { bonus } = toRefs(props)

const { filteredMultiBonuses, titleByLevel, isStepBonus } =
  useDepositBonus(bonus)
</script>

<style scoped>
.bonus-text {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  margin: 0;

  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  &.new {
    span {
      margin-left: var(--spacing-025);
      background: linear-gradient(
        90deg,
        #ffe86d 42.95%,
        #f8db90 65.11%,
        #feb581 91.78%,
        #f09f7c 100%
      );
      background-clip: text;

      -webkit-text-fill-color: transparent;
    }
  }

  &.sub-bonuses {
    overflow: hidden;
    flex-wrap: wrap;
    max-width: 200px;
  }
}

.sub-bonus {
  overflow: hidden;
  display: flex;
  align-items: center;

  .icon {
    min-width: 14px;
    margin: 0 var(--spacing-025);
  }
}
</style>
